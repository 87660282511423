import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Tooltip, IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { blue } from '@mui/material/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface DataRow {
    minutes: number;
    density: number;
    lossPercentage: number;
    population: number;
    clientiPoi: number;
}

interface TablePolygonProps {
    data: any[][];
    fascePoi: any[];
    selectedRow: number | null;
    accountType: string | null;
}

interface FascePoiMap {
    [key: string]: number;
}

const LockedContent = ({ message }: { message: string; }) => (
    <Tooltip title={message} arrow>
        <IconButton>
            <LockIcon color="disabled" />
        </IconButton>
    </Tooltip>
);


const LockedTableRowContent = ({ message, colSpan }: { message: string, colSpan: number; }) => (
    <TableRow>
        <TableCell colSpan={colSpan} sx={{ textAlign: 'center', backgroundColor: 'aliceblue' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                <LockIcon color="disabled" />
                <Typography variant="subtitle2">{message}</Typography>
            </Box>
        </TableCell>
    </TableRow>
);


export default function TablePolygon(props: TablePolygonProps) {
    const { data, fascePoi } = props;
    const rows: DataRow[] = data.map((item) => {
        const clientiPoi = Math.round(fascePoi[item[0].toString()]) || 0;
        const potenzialiClientiDopoPerdita = Math.round(item[3]);
        return {
            minutes: item[0],
            density: Math.round(item[1]),
            lossPercentage: item[2],
            population: potenzialiClientiDopoPerdita + clientiPoi,
            // Usa il valore di minutes come chiave per accedere a fascePoi
            clientiPoi: Math.round(fascePoi[item[0].toString()]) || 0
        };
    });

    const formatNumber = (number: any) => {
        return new Intl.NumberFormat('it-IT').format(number);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <caption>Suddivisione dati per isocrona</caption>
                <TableHead>
                    <TableRow>
                        <TableCell><b>Raggio isocrona (minuti)</b></TableCell>
                        <TableCell align="right">
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <b>Densità demografica</b>
                                <Tooltip title="La Densità Demografica corrisponde alla misura del numero di persone che abitano in una determinata isocrona">
                                    <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px", marginLeft: 1 }} />
                                </Tooltip>
                            </Box>
                        </TableCell>
                        {/*<TableCell align="right">
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <b>Affluenza da POI</b>
                                <Tooltip title="L'Affluenza da POI rappresenta l'impatto che diversi Punti di Interesse (POI) hanno sul flusso di persone in una determinata area. Questo valore è calcolato considerando due tipi di POI: positivi e negativi.">
                                    <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px", marginLeft: 1 }} />
                                </Tooltip>
                            </Box>
                        </TableCell>*/}
                        <TableCell align="right">
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <b>Potenziali clienti</b>
                                <Tooltip title="Il valore è dato dalla somma della densità demografica, adeguata alla distanza nelle diverse isocrone, a cui si aggiunge l'effetto dell'affluenza da POI. ">
                                    <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px", marginLeft: 1 }} />
                                </Tooltip>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {props.accountType === 'Free' ? (
                    <LockedTableRowContent message="EFFETTUA L'UPGRADE AL PIANO PREMIUM PER VISUALIZZARE I DATI COMPLETI" colSpan={4} />
                ) : (
                    <TableBody>
                        {rows.map((row, index) => {
                            // Calcola l'indice invertito
                            const invertedIndex = rows.length - 1 - index;

                            return (
                                <TableRow
                                    key={index}
                                    style={invertedIndex === props.selectedRow ? { backgroundColor: 'lightgray' } : {}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.minutes} min
                                    </TableCell>
                                    <TableCell align="right">{formatNumber(row.density)}</TableCell>
                                    {/*<TableCell align="right">{row.lossPercentage}%</TableCell>*/}
                                    {/*<TableCell align="right">
                                    {props.accountType === 'Guest' ? (
                                        <LockedContent message="Effettua lìupgrade al piano Premium per visualizzare i dati completi" />
                                    ) : (
                                        row.clientiPoi // Il dato effettivo che vuoi mostrare per gli utenti Premium
                                    )}
                                    </TableCell>*/}
                                    <TableCell align="right">
                                    {props.accountType === 'Guest' ? (
                                        <LockedContent message="Effettua lìupgrade al piano Premium per visualizzare i dati completi" />
                                    ) : (
                                        formatNumber(row.population) // Il dato effettivo che vuoi mostrare per gli utenti Premium
                                    )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
}
