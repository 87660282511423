import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Box, Button, styled } from '@mui/material';

interface Props {
    currentData: string;
    onUpdate: (data: string) => void;
    handleBack: () => void;
    handleNext: () => void;
}

interface AddressComponent {
    long_name: string;
    types: string[];
}


const StyledButton = styled(Button)({
    backgroundColor: '#163A5F', // Blue shade for primary buttons
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6', // Slightly darker shade on hover
    }
});

export default class AddressForm extends React.Component<Props, {
    address: any,
    autocomplete: any
}> {
    addressInputRef: any | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            address: props.currentData,
            autocomplete: null
        }
    }
    componentDidMount(): void {

        const loadGoogleScript = () => {
            if (!window.google) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAny-NuyTvLr-ALclrmAcXl170gWiNY7U4&libraries=places`;
                document.body.appendChild(script);
                script.onload = initAutocomplete;
            } else {
                initAutocomplete();
            }
        };

        const initAutocomplete = () => {
            if (this.addressInputRef && !this.state.autocomplete) {
                const autocompleteInstance = new window.google.maps.places.Autocomplete(
                    this.addressInputRef,
                    { types: ['geocode'] }
                );

                autocompleteInstance.addListener('place_changed', () => {
                    const selectedPlace = autocompleteInstance.getPlace();
                    if (selectedPlace.formatted_address) {
                        this.setState({
                            address: selectedPlace.formatted_address
                        });
                        this.props.onUpdate(selectedPlace.formatted_address);
                    }
                });
                this.setState({
                    autocomplete: autocompleteInstance
                });
            }
        };

        loadGoogleScript();
    }

    handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            address: event.target.value
        });
        this.onUpdate(event.target.value);
    }

    onUpdate(autocomplete: any) {
        if (autocomplete) {
            window.google.maps.event.clearInstanceListeners(autocomplete);
        }

        this.props.onUpdate(this.state.address);
    }

    handleNext = async () => {
        const tipologiaAccount = sessionStorage.getItem('tipologiaAccount');
        if (tipologiaAccount === 'Demo') {
            try {
                const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(this.state.address)}&key=AIzaSyAny-NuyTvLr-ALclrmAcXl170gWiNY7U4`);
                const data = await response.json();
                console.log(data.results[0]);
                const addressComponents: AddressComponent[] = data.results[0].address_components;
                const isBari = addressComponents.some((component: AddressComponent) => component.long_name === 'Bari' && component.types.includes('administrative_area_level_3'));

                if (!isBari) {
                    alert("Gli utenti Demo possono inserire solo indirizzi nella città di Bari.");
                    return;
                }
            } catch (error) {
                alert("Errore nel controllo dell'indirizzo. Riprova.");
                return;
            }
        }
        this.props.handleNext();
    };



    render(): React.ReactNode {
        return (
            <React.Fragment>
                <Typography variant="h6" fontFamily={'Rubik'} gutterBottom>
                    Inserisci indirizzo con numero civico
                </Typography>
                <Typography variant="caption" display="block" gutterBottom fontFamily={'Quicksand'}>
                    Inserisci l'indirizzo di partenza, sarà il punto centrale dal quale partirà l'elaborazione. Le città attualmente disponibili sono Milano, Brescia, Torino e Bari.
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="address1"
                            name="address1"
                            label="Address line 1"
                            fullWidth
                            autoComplete="shipping address-line1"
                            variant="standard"
                            inputRef={ref => { this.addressInputRef = ref }}
                            value={this.state.address}
                            onChange={this.handleInputChange.bind(this)}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <StyledButton
                        variant="contained"
                        onClick={this.handleNext}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Continua
                    </StyledButton>
                </Box>
            </React.Fragment >
        );
    }



}
