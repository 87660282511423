import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Alert, Button, Chip, CircularProgress, Stack, styled } from '@mui/material';
import Box from "@mui/material/Box";

interface Props {
    currentData: string;
    onUpdate: (data: string) => void;
    handleBack: () => void;
    handleNext: () => void;
    availableCategory: any[];
    addressSelected: string;
}

const StyledButton = styled(Button)({
    backgroundColor: '#163A5F',
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6',
    }
});

async function extractProvinciaFromGoogleMaps(address: string | number | boolean) {
    const apiKey = 'AIzaSyAny-NuyTvLr-ALclrmAcXl170gWiNY7U4';
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
    const data = await response.json();

    if (data.results && data.results.length > 0) {
        const provinciaComponent = data.results[0].address_components.find((component: { types: string | string[]; }) => component.types.includes('administrative_area_level_2'));
        if (provinciaComponent) {
            return provinciaComponent.short_name;
        }
    }

    return "XXXX";
}

export default function SelectCategory(props: Props) {
    const [selectedCategory, setSelectedCategory] = React.useState<string>(props.currentData);
    const [loading, setLoading] = React.useState(false);
    const [provincia, setProvincia] = React.useState("");

    React.useEffect(() => {
        const fetchProvincia = async () => {
            setLoading(true); // Inizia il caricamento
            const province = await extractProvinciaFromGoogleMaps(props.addressSelected);
            setProvincia(province); // Aggiorna la provincia nello stato
            setLoading(false); // Termina il caricamento
        };

        fetchProvincia();
    }, [props.addressSelected]);

    // Filtra le categorie disponibili in base alla provincia
    const filteredCategories = props.availableCategory.filter(category => category.provincia === provincia);

    const handleSelectCategory = (categoria: string) => {
        if (selectedCategory == categoria)
            categoria = '';

        setSelectedCategory(categoria);
        // Chiama la funzione onUpdate del componente padre per aggiornare lo stato con la categoria selezionata
        props.onUpdate(categoria);
    };

    return (
        <React.Fragment>
            <div style={{ paddingTop: "30px" }} />
            <Grid container spacing={2}>

                {loading ? ( // Se è in stato di caricamento, mostra lo spinner
                    <CircularProgress />
                ) : (
                    <>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h6" gutterBottom>
                                <b>Categoria di appartenenza</b>
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                Indica la tua categoria di appartenenza...
                            </Typography>
                            <Box sx={{ my: 2 }}>
                                <Stack direction="row" spacing={1} style={{ display: "flex", flexFlow: "wrap", justifyContent: "center" }}>
                                    {filteredCategories.length !== 0 ? (
                                        filteredCategories.map((category) => (
                                            <Chip
                                                label={category.nome_tipologia}
                                                key={category.categoria}
                                                clickable
                                                variant="outlined"
                                                color={selectedCategory === category.categoria ? 'primary' : 'default'}
                                                onClick={() => handleSelectCategory(category.categoria)}
                                                style={{ fontSize: '20px', height: '50px', margin: '5px', borderRadius: "100px" }}
                                            />
                                        ))
                                    ) : (
                                        <Stack alignContent={"center"} display={"flex"} style={{ flexFlow: "column" }}>
                                            <Alert severity="info" style={{ textAlign: "center" }}>
                                                <Typography alignSelf={"center"} variant="h4" marginBottom={"10px"}><b>COMING SOON</b></Typography>
                                                <Typography>La funzione "Categoria di appartenenza" non è ancora disponibile nella città interrogata.</Typography>
                                            </Alert>
                                        </Stack>
                                    )}
                                </Stack>

                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                </Button>
                <StyledButton
                    variant="contained"
                    onClick={props.handleNext}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Continua
                </StyledButton>
            </Box>
        </React.Fragment>
    );
}

