export class UserService {
    static signup(userInfo: any) {
        return fetch("https://backend.pythagor.it/signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userInfo)
        })
            .then(res => res.json())
            .catch(err => {
                console.error("Error during signup:", err);
                throw new Error("Errore nella connessione. Riprova più tardi.");
            });
    }

    static login(credentials: any) {
        return fetch("https://backend.pythagor.it/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(credentials)
        })
            .then(async res => {
                const data = await res.json(); // Estrai sempre il JSON, indipendentemente dallo stato della risposta

                if (!res.ok) {
                    // Se la risposta non è OK, usa il messaggio di errore personalizzato dal backend
                    throw new Error(data.message || "Errore nella connessione. Riprova più tardi.");
                }

                return data; // Se tutto va bene, restituisci i dati
            })
            .catch(err => {
                console.error("Error logging in:", err);
                throw err; // Rilancia l'errore per essere gestito dal chiamante
            });
    }


    static loginAsGuest() {
        return fetch("https://backend.pythagor.it/login/guest", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: ""
        })
            .then(res => res.json())
            .catch(err => {
                console.error("Error logging in:", err);
                throw new Error("Errore nella connessione. Riprova più tardi.");
            });
    }

    static getUser(userInfo: any) {
        return fetch("https://backend.pythagor.it/getUser", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userInfo)
        })
            .then(res => res.json())
            .catch(err => {
                console.error("Error during getting user:", err);
                throw new Error("Errore nella connessione. Riprova più tardi.");
            });
    }

    static resetPassword(pswInfo: any) {
        return fetch("https://backend.pythagor.it/resetPsw/", { // Assicurati che l'URL sia corretto
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(pswInfo)
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Errore nella risposta dal server.');
                }
                return res.json();
            })
            .catch(err => {
                console.error("Error during password reset:", err);
                throw err;
            });

    }

}
