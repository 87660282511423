import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box, Button, Radio, RadioGroup, styled } from '@mui/material';

export interface RoutingData {
    routingProfile: string;
    distanceType: string;
    distance: string;
}

interface Props {
    currentData: RoutingData;
    onUpdate: (data: RoutingData) => void;
    handleBack: () => void;
    handleNext: () => void;
}

const StyledButton = styled(Button)({
    backgroundColor: '#163A5F', // Blue shade for primary buttons
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6', // Slightly darker shade on hover
    }
});

export default function RoutingForm(props: Props) {
    const [routingProfile, setRoutingProfile] = React.useState(props.currentData.routingProfile);
    const [distanceType] = React.useState("minutes"); 
    const [distance, setDistance] = React.useState(props.currentData.distance);
    const [isGuest, setIsGuest] = React.useState(false);

    React.useEffect(() => {
        if (sessionStorage.getItem('tipologiaAccount') === 'Guest') {
            setIsGuest(true);
            setRoutingProfile('walking');
            setDistance('7');
        } else {
            setIsGuest(false);
        }
    }, []);

    React.useEffect(() => {
        props.onUpdate({
            routingProfile,
            distanceType,
            distance,
        });
    }, [routingProfile, distance]);

    const updateSessionRoutingProfile = (newValue:string) => {
        const labels: { [key: string]: string } = {
            'walking': 'A piedi',
            'cycling': 'In bicicletta',
            'driving': 'In macchina',
            'driving-traffic': 'In macchina con condizioni di traffico',
        };
    
        sessionStorage.setItem('routing_profile_selected', labels[newValue] || '');
    };
    
    React.useEffect(() => {
        updateSessionRoutingProfile(routingProfile);
    }, [routingProfile]);

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                <b>Profilo di avvicinamento</b>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                Seleziona un solo profilo di avvicinamento che l'algoritmo utilizzerà per calcolare le zone raggiungibili.
            </Typography>
            <Grid item xs={12} md={6}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={routingProfile}
                    onChange={e => setRoutingProfile(e.target.value)}
                    name="radio-buttons-group"
                >
                    <FormControlLabel 
                        value="walking" 
                        control={<Radio />} 
                        label="A piedi" 
                        disabled={isGuest} 
                    />
                    <FormControlLabel value="cycling" control={<Radio />} label="In bicicletta" disabled={isGuest}/>
                    <FormControlLabel value="driving" control={<Radio />} label="In macchina" disabled={isGuest}/>
                    <FormControlLabel value="driving-traffic" control={<Radio />} disabled={isGuest} label="In macchina con condizioni di traffico" />
                </RadioGroup>
            </Grid>
            {/*<Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom sx={{ padding: "15px 0px" }}>
                    <b>Tipo distanza in tempo</b>
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Il tipo di distanza è impostato di default su minuti.
                </Typography>
                <TextField
                    disabled
                    id="disabled"
                    label="Tipo"
                    defaultValue="Minuti"
                    variant="standard"
                />
            </Grid>*/}
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ padding: "15px 0px" }}>
                    <b>Minuti (max 60)</b>
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Indica la distanza in tempo che dovrà essere presa in considerazione a partire dal punto di partenza.
                </Typography>
                <TextField
                    required
                    id="distanza"
                    name="distanza"
                    label="Distanza"
                    type="number"
                    variant="standard"
                    value={distance}
                    onChange={e => {
                        if (!isGuest) {
                            const inputValue = e.target.value.replace(/\D/g, '');
                            if (inputValue === '' || (parseInt(inputValue, 10) >= 1 && parseInt(inputValue, 10) <= 60)) {
                                setDistance(inputValue);
                            }
                        }
                    }}
                    inputProps={{
                        pattern: "[0-9]*",
                    }}
                    style={{ width: "10%" }}
                    disabled={isGuest}
                />
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => props.handleBack()} sx={{ mt: 3, ml: 1 }}>
                    Back
                </Button>
                <StyledButton
                    variant="contained"
                    onClick={() => props.handleNext()}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Continua
                </StyledButton>
            </Box>
        </React.Fragment>
    );
}
