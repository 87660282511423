import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import RoutingForm, { RoutingData } from './RoutingForm';
import ViewInformation from './ViewInformation';
import RegistryPoiForm from "./RegistryPoiForm";
import { useEffect, useState } from "react";
import DataProcessingScreen from "./DataProcessing";
import { MobileStepper, ThemeProvider, createTheme, styled } from "@mui/material";
import ResponsiveAppBar from "../../component/CustomNavbar";
import Copyright from '../../component/Copyright';
import FinalPage from './FinalPage';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import DemographicSelectionForm from './DemographicSelectionForm';
import SelectCategory from './SelectCategory';

const StyledButton = styled(Button)({
    backgroundColor: '#163A5F', // Blue shade for primary buttons
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6', // Slightly darker shade on hover
    }
});

const theme = createTheme({
    typography: {
        fontFamily: [
            'Quicksand',
            'sans-serif',
        ].join(','),
        h6: {
            fontFamily: 'Rubik, sans-serif',
        },
    },
});


export default function ProcessingRoute() {
    const steps = ['Indirizzo', 'Avvicinamento', 'Anagrafica', 'Categoria', 'Punti di Interesse (POI)', 'Elaborazione', 'Risultato'];

    const [activeStep, setActiveStep] = useState<number>(0);
    const [hideButtons, setHideButtons] = useState<boolean>(false);
    const [processedData, setProcessedData] = useState<any>(null);
    const [processedError, setProcessedError] = useState<any>(null);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        if (activeStep == steps.length - 2) {
            // Se siamo nella penultima pagina nasconi pulsanti
            setHideButtons(true);
        } else {
            setHideButtons(false);
        }
    }, [activeStep]);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const homeBack = () => {
        setActiveStep(0);
    };
    const [formData, setFormData] = React.useState({
        address: "",
        routing: {
            routingProfile: "",
            distanceType: "Minuti",
            distance: "",
        },
        demographicTarget: [],
        registryPoi: {
            demographicTarget: [],
            positivePois: [],
            negativePois: [],
            descriptivePois: []
        },
        category: ""
    });
    function handleUpdate(key: 'address' | 'routing' | 'demographicTarget' | 'registryPoi' | 'category', data: RoutingData | any) {
        setFormData(prev => ({
            ...prev,
            [key]: data,
        }));
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <AddressForm
                    currentData={formData.address}
                    onUpdate={(data) => handleUpdate('address', data)}
                    handleBack={() => handleBack()}
                    handleNext={() => handleNext()}
                />;
            case 1:
                return <RoutingForm
                    currentData={formData.routing}
                    onUpdate={(data) => handleUpdate('routing', data)}
                    handleBack={() => handleBack()}
                    handleNext={() => handleNext()}
                />;
            case 2:
                return <DemographicSelectionForm
                    currentData={{ demographicTarget: formData.demographicTarget }}
                    onUpdate={(data) => handleUpdate('demographicTarget', data)}
                    handleBack={() => handleBack()}
                    handleNext={() => handleNext()}
                />;
            case 3:
                const availableCategory = JSON.parse(sessionStorage.getItem('availableCategory') || '{}');
                return <SelectCategory
                    currentData={formData.category}
                    addressSelected={formData.address}
                    availableCategory={availableCategory}
                    onUpdate={(data) => handleUpdate('category', data)}
                    handleBack={() => handleBack()}
                    handleNext={() => handleNext()}
                />;
            case 4:
                const poiData = JSON.parse(sessionStorage.getItem('poiData') || '{}');
                return <RegistryPoiForm
                    currentData={formData.registryPoi}
                    poiData={poiData}
                    onUpdate={(data) => handleUpdate('registryPoi', data)}
                    handleBack={() => handleBack()}
                    handleNext={() => handleNext()}
                />;
            case 5:
                return <DataProcessingScreen collectedData={formData}
                    onDataProcessed={(data, error) => {
                        setProcessedData(data);
                        setProcessedError(error);
                        setActiveStep(activeStep + 1);
                    }} />;
            case 6:
                if (processedError !== null) {
                    return <div>Loading or error...</div>;
                }
                return <ViewInformation
                    data={processedData}
                    handleNext={() => handleNext()}
                />;

            default:
                throw new Error('Unknown step');
        }
    }

    const renderStepper = () => {
        if (isSmallScreen) {
            // Render MobileStepper for small screens
            return (
                <div>
                    <MobileStepper
                        variant="progress"
                        steps={steps.length}
                        sx={{ maxWidth: 600, flexGrow: 1, placeContent: 'center' }}
                        position="static"
                        activeStep={activeStep}
                        nextButton={null}
                        backButton={null}
                    />
                </div>
            );
        } else {
            // Render standard Stepper for larger screens
            return (
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                    {steps.map((label) => (
                        <Step key={label} sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: '#163A5F', // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                            {
                                color: 'white', // Just text label (COMPLETED)
                            },
                            '& .MuiStepLabel-root .Mui-active': {
                                color: '#163A5F', // circle color (ACTIVE)
                            },
                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                            {
                                color: 'white', // Just text label (ACTIVE)
                            },
                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: 'white', // circle's number (ACTIVE)
                            },
                        }}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            );
        }
    };


    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                {/*<CssBaseline />*/}
                <ResponsiveAppBar />
                <Container component="main" maxWidth="lg" sx={{ mb: 4, mt: 10 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        {renderStepper()}

                        {
                            activeStep === steps.length ? (
                                <>
                                    <FinalPage homeBack={() => homeBack()} />
                                </>
                            ) : (
                                <>
                                    {getStepContent(activeStep)}
                                </>
                            )
                        }
                    </Paper>
                    <Copyright />
                </Container>
            </React.Fragment>
        </ThemeProvider>
    );
}
