import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button, styled } from '@mui/material';
import Box from "@mui/material/Box";
import MultiSelectInput from "../../component/MultiSelectInput";
import MultiSelectExpandable from '../../component/MultiSelectExpandable';
import MultiSelectWithMacro from '../../component/MultiSelectExpandable';

interface RegistryPoiFormData {
    positivePois: string[];
    negativePois: string[];
    descriptivePois: string[];
}

interface MultiSelectInputProps {
    options: string[];
    label: string;
    selectedValues?: string[];  // aggiunto questo
    onChange?: (selected: string[]) => void;  // e questo
    // ... altre props che potresti avere
}

interface PoiData {
    positivo: any[];
    negativo: any[];
    descrittivo: any[];
}

interface Props {
    currentData: RegistryPoiFormData;
    onUpdate: (data: RegistryPoiFormData) => void;
    handleBack: () => void;
    handleNext: () => void;
    poiData: PoiData; // Usa la nuova interfaccia PoiData qui
}

const StyledButton = styled(Button)({
    backgroundColor: '#163A5F', // Blue shade for primary buttons
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6', // Slightly darker shade on hover
    }
});

export default function RegistryPoiForm(props: Props) {
    const [positivePois, setPositivePois] = React.useState<string[]>(props.currentData.positivePois);
    const [negativePois, setNegativePois] = React.useState<string[]>(props.currentData.negativePois);
    const [descriptivePois, setDescriptivePois] = React.useState<string[]>([]);
    const handleUpdate = (key: keyof RegistryPoiFormData, data: any) => {
        if (key === 'positivePois') setPositivePois(data);
        if (key === 'negativePois') setNegativePois(data);
        if (key === 'descriptivePois') setDescriptivePois(data);
        // Invia i dati aggiornati al componente padre
        props.onUpdate({
            positivePois,
            negativePois,
            descriptivePois,
            ...{ [key]: data }
        });
    };

    const [isGuest, setIsGuest] = React.useState(false);

    React.useEffect(() => {
        setIsGuest(sessionStorage.getItem('tipologiaAccount') === 'Guest');
    }, []);


    return (

        <React.Fragment>
            <div style={{ paddingTop: "30px" }} />
            <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                    <b>POI positivi</b>
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Indica uno o più POI (point of interest) in grado di aumentare i potenziali clienti per la tua attività.
                </Typography>
                <Grid item xs={12} md={6}>
                    <MultiSelectWithMacro
                        poiData={props.poiData.positivo}
                        label="Seleziona POI positivi"
                        disabledSelect={isGuest}
                        selectedValues={positivePois}
                        onChange={(selected) => handleUpdate('positivePois', selected)}

                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                    <b>POI descrittivi</b>
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Scegli i POI che arricchiscono la descrizione su mappa del risultato, ma non sono presi in considerazione nel calcolo del modello statistico.
                </Typography>
                <Grid item xs={12} md={6}>
                    <MultiSelectWithMacro
                        poiData={props.poiData.descrittivo}
                        label="Seleziona POI descrittivi"
                        selectedValues={descriptivePois} // Utilizza il nuovo stato qui
                        onChange={(selected) => handleUpdate('descriptivePois', selected)} // Aggiorna qui
                        disabledSelect={isGuest}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                    <b>POI negativi</b>
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Seleziona le attività che riducono il bacino di clientela potenziale.
                </Typography>
                <Grid item xs={12} md={6}>
                    <MultiSelectWithMacro
                        poiData={props.poiData.negativo}
                        label="Seleziona POI negativi"
                        selectedValues={negativePois}
                        disabledSelect={isGuest}
                        onChange={(selected) => handleUpdate('negativePois', selected)}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => props.handleBack()} sx={{ mt: 3, ml: 1 }}>
                    Back
                </Button>
                <StyledButton
                    variant="contained"
                    onClick={() => props.handleNext()}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Continua
                </StyledButton>
            </Box>
        </React.Fragment>
    );
}
