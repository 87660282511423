import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MapDisplay from "../../component/MapDisplay";
import Avatar from '@mui/material/Avatar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { blue } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import TablePolygon from "../../component/TablePolygon";
import { Alert, Box, Button, IconButton, styled } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import ReportComponent from './ReportComponent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { describe_selected_poi, distance, negative_selected_poi, positive_selected_poi, question, selected_options } from './DataProcessing';

interface POI {
    lat: number;
    lng: number;
    name: string;
    type: string;
}

interface ViewInformationProps {
    data: any;
    handleNext: () => void;
}

const StyledButton = styled(Button)({
    backgroundColor: '#163A5F', // Blue shade for primary buttons
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6', // Slightly darker shade on hover
    }
});

const LockedContent = ({ message }: { message: string; }) => (
    <Tooltip title={message} arrow>
        <IconButton>
            <LockIcon color="disabled" />
        </IconButton>
    </Tooltip>
);

export default function ViewInformation(props: ViewInformationProps) {
    let density = 0;
    let customers = 0;
    let account_tipology = sessionStorage.getItem('tipologiaAccount');
    if (props.data && props.data[0]) {
        props.data[0].forEach(function (singolaZona: any) {
            density += singolaZona[1];
            customers += singolaZona[3];
        });
    }

    density = Math.round(density);
    customers = props.data[0].reduce((acc: number, item: number[]) => {
        const clientiPoi = Math.round(props.data[11][item[0].toString()]) || 0;
        const potenzialiClientiDopoPerdita = Math.round(item[3]);
        return acc + (potenzialiClientiDopoPerdita + clientiPoi);
    }, 0);
    if (customers < 0)
        customers = 0;
    let poiConcorrenziali = props.data[3].length;
    let poiPositivi = props.data[4].length;
    const getUniquePOITypes = (data: POI[]): string[] => {
        const types = data.map(item => item.type);
        return Array.from(new Set(types));
    };
    const tooltipTextPositivePoi = `I POI (point of interest) positivi presenti nella zona.`;
    const tooltipTextDescrittiviPoi = 'I POI (point of interest) descrittivi presenti nella zona.'
    const tooltipTextNegativePoi = `I POI (point of interest) concorrenziali presenti nella zona.`;
    const [selectedPolygonIndex, setSelectedPolygonIndex] = useState<number | null>(null);
    const poiData = JSON.parse(sessionStorage.getItem('poiData') || '{}');
    const findPoiLabels = (selectedValues: string[], poiList: any[]) => {
        // Creazione di un Set per eliminare i duplicati
        const labelsSet = new Set(
            selectedValues.map(value =>
                poiList.find(poi => poi.codifica_tipologia === value)?.nome_tipologia
            ).filter(label => label) // Filtra eventuali valori undefined/null
        );

        // Conversione del Set in un array e unione delle stringhe con ', '
        return Array.from(labelsSet).join(', ');
    };
    const positivePoiLabels = findPoiLabels(positive_selected_poi, poiData.positivo || []);
    const negativePoiLabels = findPoiLabels(negative_selected_poi, poiData.negativo || []);
    const descriptivePoiLabels = findPoiLabels(describe_selected_poi, poiData.descrittivo || []);

    const onPolygonSelect = (index: number) => {
        setSelectedPolygonIndex(index);
    };
    const [openReport, setOpenReport] = useState(false);
    const handleGenerateReport = () => {
        setOpenReport(true);
    };
    const formatNumber = (number: any) => {
        return new Intl.NumberFormat('it-IT').format(number);
    };
    const indiceMedioAttrattivo = props.data[16] == 0 ? "-" : formatNumber(props.data[16]);



    return (
        <React.Fragment>
            <>
                <Alert icon={false} severity="info" sx={{ textTransform: "lowercase", maxWidth: "fit-content", fontSize: "small", marginBottom: "25px", marginInline: "auto" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <strong>indirizzo: </strong>{question}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <strong>avvicinamento: </strong>{sessionStorage.getItem('routing_profile_selected')}
                            <br />
                            <strong>distanza: </strong>{distance} minuti
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <strong>obiettivo anagrafico: </strong>{selected_options.join(', ')}
                            <br />
                            <strong>POI positivi: </strong>{positivePoiLabels}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <strong>POI concorrenziali: </strong>{negativePoiLabels}
                            <br />
                            <strong>POI descrittivi: </strong>{descriptivePoiLabels}
                        </Grid>
                    </Grid>
                </Alert>

                <Box display="flex" justifyContent="space-between">
                    <Box flex={1} p={1}>
                        <Typography variant="subtitle1" gutterBottom>
                            Densità demografica
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            {formatNumber(density)}
                        </Typography>
                    </Box>
                    <Box flex={1} p={1}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                POI concorrenziali
                            </Typography>
                            <Tooltip title={tooltipTextNegativePoi}>

                                <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px" }} />
                            </Tooltip>
                        </div>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography variant="h5">
                                    {account_tipology === 'Guest' ? (
                                        <LockedContent message="Effettua lìupgrade al piano Premium per visualizzare i dati completi" />
                                    ) : (
                                        poiConcorrenziali // Il dato effettivo che vuoi mostrare per gli utenti Premium
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar
                                    alt="Icona Concorrenziali"
                                    src="/sign.png"
                                    variant="rounded"
                                    sx={{ width: 15, height: 15 }}  // <-- Riduci le dimensioni qui
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box flex={1} p={1}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                POI positivi
                            </Typography>
                            <Tooltip title={tooltipTextPositivePoi}>

                                <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px" }} />
                            </Tooltip>
                        </div>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography variant="h5">
                                    {account_tipology === 'Guest' ? (
                                        <LockedContent message="Effettua l'upgrade al piano Premium per visualizzare i dati completi" />
                                    ) : (
                                        poiPositivi
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar
                                    alt="Icona Positivi"
                                    src="/plus.png"
                                    variant="rounded"
                                    sx={{ width: 17, height: 17 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box flex={1} p={1}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                POI descrittivi
                            </Typography>
                            <Tooltip title={tooltipTextDescrittiviPoi}>

                                <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px" }} />
                            </Tooltip>
                        </div>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography variant="h5">
                                    {account_tipology === 'Guest' ? (
                                        <LockedContent message="Effettua l'upgrade al piano Premium per visualizzare i dati completi" />
                                    ) : (
                                        props.data[15].length
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar
                                    alt="Icona Descrittivi"
                                    src="/information.png"
                                    variant="rounded"
                                    sx={{ width: 20, height: 20 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box flex={1} p={1}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Potenziali clienti
                            </Typography>
                            <Tooltip title="Il dato viene calcolato sottraendo i poi positivi/negativi e la percentuale demografica nelle varie isocrone alla densità totale nell'area...">

                                <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px" }} />
                            </Tooltip>
                        </div>
                        <Typography variant="h5" gutterBottom>
                            {account_tipology === 'Guest' ? (
                                <LockedContent message="Effettua l'update al piano Premium per visualizzare i dati completi" />
                            ) : (
                                formatNumber(customers)
                            )}
                        </Typography>
                    </Box>
                    <Box flex={1} p={1}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Indice AT&C
                            </Typography>
                            <Tooltip title="L'indice di attrazione AT&C (attraction type & city) è dato dalla media dei potenziali clienti per la città interrogata. L'indice è calcolato con la distanza di 5 min a piedi...">

                                <InfoOutlinedIcon sx={{ color: blue[200], maxWidth: "20px" }} />
                            </Tooltip>
                        </div>
                        <Typography variant="h5" gutterBottom>
                            {account_tipology === 'Guest' ? (
                                <LockedContent message="Effettua l'update al piano Premium per visualizzare i dati completi" />
                            ) : (
                                indiceMedioAttrattivo
                            )}
                        </Typography>
                    </Box>
                </Box>

                <MapDisplay data={props.data} onPolygonSelect={onPolygonSelect} />
                <TablePolygon data={props.data[0]} fascePoi={props.data[11]} selectedRow={selectedPolygonIndex} accountType={account_tipology} />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {account_tipology !== 'Guest' ? (
                        <StyledButton
                            variant="contained"
                            onClick={handleGenerateReport}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Genera Report
                        </StyledButton>
                    ) : null}
                    <Dialog
                        open={openReport}
                        keepMounted
                        onClose={() => setOpenReport(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <ReportComponent onPolygonSelect={onPolygonSelect} data={props.data} onClose={() => setOpenReport(false)} />
                        </DialogContent>
                    </Dialog>
                    <StyledButton
                        variant="contained"
                        onClick={() => props.handleNext()}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Fine
                    </StyledButton>
                </Box>
            </>
        </React.Fragment>
    );
};
