import { Link, Typography } from "@mui/material";

export default function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            Copyright ©
            <Link color="inherit" href="http://www.pythagor.it/" target="_blank">
                Pythagor SRLS
            </Link>
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}