import { CircularProgress, Typography, Box, Alert } from "@mui/material";
import { useEffect, useState } from "react";

interface RedirectCountdownProps {
    initialCount: number;
    onComplete: () => void;
}

const RedirectCountdown: React.FC<RedirectCountdownProps> = ({ initialCount, onComplete }) => {
    const [count, setCount] = useState(initialCount);
    const progress = (count / initialCount) * 100;

    useEffect(() => {
        if (count > 0) {
            const timerId = setTimeout(() => {
                setCount(count - 1);
            }, 1000);

            return () => {
                clearTimeout(timerId);
            };
        } else {
            onComplete();
        }
    }, [count, onComplete]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">

            <Typography variant="subtitle1">Registrazione effettuata! Verrai reindirizzato alla pagina di login in <i>{count} secondi</i></Typography>

            <CircularProgress variant="determinate" value={100 - progress} size={80} />
        </Box>
    );
};

export default RedirectCountdown;
