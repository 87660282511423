import { Button, Typography, styled } from "@mui/material";

interface FinalPageProps {
    homeBack: () => void;
}

const StyledButton = styled(Button)({
    backgroundColor: '#163A5F', // Blue shade for primary buttons
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6', // Slightly darker shade on hover
    }
});

function FinalPage(props: FinalPageProps) {
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Grazie per aver utilizzato Pythagor!
            </Typography>
            <Typography variant="subtitle1">
                Grazie per avere utilizzato Pythagor, torna alla pagina principale per eseguire un'altra elaborazione...
            </Typography>
            <StyledButton onClick={() => props.homeBack()} sx={{ mt: 3, ml: 1 }} variant="contained">
                Home
            </StyledButton>
        </div>
    );

}

export default FinalPage;