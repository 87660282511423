import * as React from 'react';
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/AccessPages/LoginPage';
import SignupPage from './pages/AccessPages/SignupPage';
import SettingsPage from './pages/SettingsPages/SettingsPage';
import ProcessingRoute from './pages/ModelloStradaPages/ProcessingRoute';

interface PrivateRouteProps {
    children: React.ReactNode;
}


const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const jwtToken = sessionStorage.getItem('jwtToken');
    return jwtToken ? <>{children}</> : <Navigate to="/" />;
};



function InitializePage() {
    const handleLoginSuccess = () => {
        // Implement what should happen on login success
    };

    const handleSignupSuccess = () => {
        // Implement what should happen on signup success
    };

    const handleSwitchToSignup = () => {
        // Implement the action to switch to the signup page
    };

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} onSwitchToSignup={handleSwitchToSignup} />} />
                <Route path="/signup" element={<SignupPage onSignupSuccess={handleSignupSuccess} />} />
                <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
                <Route path="/processing" element={<PrivateRoute><ProcessingRoute /></PrivateRoute>} />
                <Route path="/" element={<HomePage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default InitializePage;
