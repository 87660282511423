import React, { useEffect } from 'react';
import LoadingScreen from '../../component/LoadingScreen';
import { StradaService } from '../../services/StradaService';
import './dataProcessingStyle.css';

type FormData = {
    address: string;
    routing: {
        routingProfile: string;
        distanceType: string;
        distance: string;
    };
    demographicTarget: string[];
    registryPoi: {
        positivePois: any[];  
        negativePois: any[];  
        descriptivePois: any[];
    };
    category: string;
};

type FixedData = {
    question: string;
    routing_profile: string;
    countour_type: string;
    distance: string;
    selected_options: string[];
    positive_poi: string[];
    negative_poi: string[];
    descriptive_poi: string[];
    email_account: string | null;
    category: string;
};

interface DataProcessingProps {
    collectedData: FormData;
    onDataProcessed: (data: any | null, error: string | null) => void;
}

export let question: string = "";
export let distance:string = "";
export let selected_options:string[] = [];
export let positive_selected_poi:string[] = [];
export let negative_selected_poi:string[] = [];
export let describe_selected_poi:string[] = [];


const DataProcessingScreen: React.FC<DataProcessingProps> = ({ collectedData, onDataProcessed }) => {
    
    const transformData = (collectedData: FormData): FixedData => {

        question = collectedData.address;
        distance = collectedData.routing.distance;
        selected_options = collectedData.demographicTarget;
        positive_selected_poi = collectedData.registryPoi.positivePois;
        negative_selected_poi = collectedData.registryPoi.negativePois;
        describe_selected_poi = collectedData.registryPoi.descriptivePois;
        
        return {
            question: collectedData.address,
            routing_profile: collectedData.routing.routingProfile,
            countour_type: collectedData.routing.distanceType,
            distance: collectedData.routing.distance,
            selected_options: collectedData.demographicTarget,
            positive_poi: collectedData.registryPoi.positivePois,
            negative_poi: collectedData.registryPoi.negativePois,
            descriptive_poi: collectedData.registryPoi.descriptivePois,
            email_account: sessionStorage.getItem('emailAccount'),
            category: collectedData.category
        };
    };
    const [error, setError] = React.useState<string | null>(null);

    useEffect(() => {
        const fixedData = transformData(collectedData);
        StradaService.fetchPlaces(fixedData)
            .then(data => {
                if (data[9].success) {
                    onDataProcessed(data, null); // No error
                }
                else {
                    console.error("Failed to get answer:", data);
                    setError("Something went wrong while processing your data. Please try again later.");
                    onDataProcessed(null, data.message);
                }
            })
            .catch(err => {
                console.error("Failed to get answer:", err);
                setError("Something went wrong while processing your data. Please try again later.");
                onDataProcessed(null, err.message);
                // Pass the error message
            });
    }, []);


    return (
        error ?
            <div className="error-screen">
                <p>{error}</p>
            </div>
            :
            <LoadingScreen isVisible={true} />
    );

};

export default React.memo(DataProcessingScreen);
