import { useEffect, useState } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Facebook, Twitter } from '@mui/icons-material';
import './style.css';
import { UserService } from '../../services/UserService';
import SignupPage from './SignupPage';
import { json } from 'stream/consumers';

interface LoginPageProps {
  onLoginSuccess: () => void;
  onSwitchToSignup: () => void;
}

function LoginPage({ onLoginSuccess, onSwitchToSignup }: LoginPageProps) {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState<string | null>(null);
  interface LoginResponse {
    success: boolean;
    message: string;
    poiData: any[];
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openDialog, setOpenDialog] = useState(false);

  function parseJwt(token:string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogin = () => {
    if (!credentials.email || !credentials.password) {
      setError("Inserisci sia l'email che la password.");
      return;
    }

    UserService.login(credentials)
      .then((data) => {
        if (data.success) {
          sessionStorage.setItem('jwtToken', data.access_token); // Salva il token JWT
          const jwtToken = sessionStorage.getItem('jwtToken');
          const decodedToken = parseJwt(jwtToken!);

          if (decodedToken) {
            sessionStorage.setItem('nomeAccount', decodedToken.nome);
            sessionStorage.setItem('cognomeAccount', decodedToken.cognome);
            sessionStorage.setItem('emailAccount', decodedToken.sub); // 'sub' è solitamente usato per l'email o l'ID utente
            sessionStorage.setItem('tipologiaAccount', decodedToken.tipo_account);
          } else {
            console.log('Token JWT non valido o non presente');
          }
          sessionStorage.setItem('poiData', JSON.stringify(data.poiData));
          sessionStorage.setItem('availableCategory', JSON.stringify(data.availableCategory));
          onLoginSuccess();
        } else {
          setError(data.message);
        }
      })
      .catch((err) => {
        setError(err.message);
      });

  };

  const handleLoginAsGuest = () => {

    UserService.loginAsGuest()
      .then((data) => {
        if (data.success) {
          sessionStorage.setItem('jwtToken', data.access_token); // Salva il token JWT
          const jwtToken = sessionStorage.getItem('jwtToken');
          const decodedToken = parseJwt(jwtToken!);

          if (decodedToken) {
            sessionStorage.setItem('nomeAccount', decodedToken.nome);
            sessionStorage.setItem('cognomeAccount', decodedToken.cognome);
            sessionStorage.setItem('emailAccount', decodedToken.sub);
            sessionStorage.setItem('tipologiaAccount', decodedToken.tipo_account);
          } else {
            console.log('Token JWT non valido o non presente');
          }
          sessionStorage.setItem('poiData', JSON.stringify(data.poiData));
          onLoginSuccess();
        } else {
          setError(data.message);
        }
      })
      .catch((err) => {
        setError(err.message);
      });

  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const openSignupDialog = () => {
    setOpenDialog(true);
  };

  const closeSignupDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="login-container">
      {/* Image section */}
      <div className="login-image-section">
        <img src={isMobile ? "/loginpythagor_cell.jpeg" : "/loginpythagor.jpeg"} />
      </div>

      {/* Login form section */}
      <div className="login-form-section">
        <h1>Theorem by Pythagor</h1>
        <p>Inserisci la tua email e la password per accedere al servizio</p>
        {error && <div className="error-message">{error}</div>}
        <TextField
          name="email"
          label="Email Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={credentials.email}
          onChange={handleChange}
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={credentials.password}
          onChange={handleChange}
        />
        <div className="login-actions">
          <Button style={{ backgroundColor: "#163A5F", color: "white" }} onClick={handleLogin} size="large">
            Accedi
          </Button>
        </div>
        <p>
          Continua come{" "}
          <span onClick={handleLoginAsGuest} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            Ospite
          </span>
        </p>
        <p>
          Non sei ancora registrato?{" "}
          <span onClick={openSignupDialog} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            Registrati
          </span>
        </p>
        <p>
          Contattaci: <u>info@pythagor.it</u>
        </p>
        {/*<div className="social-login">
          <IconButton color="primary"><Facebook /></IconButton>
          <IconButton color="secondary"><Twitter /></IconButton>
        </div>*/}

        {/* Signup Dialog */}
        <Dialog open={openDialog} onClose={closeSignupDialog} sx={{
          backdropFilter: "blur(5px) sepia(5%)",
        }}
          PaperProps={{ sx: { borderRadius: "20px" } }}
        >
          <DialogTitle sx={{ textAlign: "center", fontWeight: "600" }}>Registrazione</DialogTitle>
          <DialogContent>
            <SignupPage onSignupSuccess={onLoginSuccess} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSignupDialog}>Chiudi</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default LoginPage;
