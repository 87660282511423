import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { UserService } from '../services/UserService';

export default function ResetPasswordButton() {
  const [open, setOpen] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [isGuest, setIsGuest] = React.useState(false);

    React.useEffect(() => {
        // Verifica se l'utente è un ospite all'avvio del componente
        const tipoAccount = sessionStorage.getItem('tipologiaAccount');
        setIsGuest(tipoAccount == 'Guest');
      }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = (reason:any) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleResetPassword = async () => {
    let email = sessionStorage.getItem('emailAccount');
    try {
      const response = await UserService.resetPassword({
        old: currentPassword, 
        new: newPassword,
        email: email
      });
  
      if (response.success) {
        setSnackbarMessage(response.message);
        handleClose(); 
      } else {
        setSnackbarMessage(response.message);
      }
    } catch (error) {
        if (error instanceof Error) {
            setSnackbarMessage(error.message);
          } else {
            setSnackbarMessage("Si è verificato un errore sconosciuto.");
          }
    }
    setSnackbarOpen(true);
  };

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen} disabled={isGuest}>
        Reset password
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Modifica la tua password</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="current-password"
            label="Password Attuale"
            type={showCurrentPassword ? 'text' : 'password'}
            fullWidth
            variant="standard"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleCurrentPasswordVisibility}
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="new-password"
            label="Nuova Password"
            type={showNewPassword ? 'text' : 'password'}
            fullWidth
            variant="standard"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleNewPasswordVisibility}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annulla</Button>
          <Button onClick={handleResetPassword}>Modifica</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
}
