import React, {useEffect, useRef} from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './style.css';
import logoImage from '../logoPythagorMappa.png';

mapboxgl.accessToken = 'pk.eyJ1IjoiYWxkb2NhcnJvbG8iLCJhIjoiY2xsYzRmMXFvMGdnazN0cXV5MW5pMjB0dSJ9.LsIZAgufmFsv7wwkLuEwzg';

interface Props {
    data: any[];
    onPolygonSelect: (index: any) => void;
}

type LocationData = {
    name: string;
    lat: number;
    lng: number;
    type: string;
};


type GeoJSON = {
    type: string;
    features: Array<{
        type: string;
        geometry: {
            type: string;
            coordinates: [number, number];
        };
        properties: {
            title: string;
            description: string;
        };
    }>;
};


const transformToGeoJSON = (data: LocationData[]): GeoJSON  => {
    const features = data.map(location => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [location.lng, location.lat] as [number, number] // cast esplicito a tupla
        },
        properties: {
            title: location.name,
            description: location.type
        }
    }));

    return {
        type: 'FeatureCollection',
        features: features
    };
}


const getColorByIndex = (index: number, total: number, opacity: number = 0.5): string => {
    const ratio = 1 - (index / (total - 1));
    // Utilizziamo un'intensità massima ridotta per assicurare che l'esterno non sia troppo chiaro.
    const maxIntensity = 100; // Limita l'intensità massima per un colore più scuro
    const intensity = Math.floor(maxIntensity * ratio);
    return `rgba(${intensity}, ${intensity}, ${intensity}, ${opacity})`;
};


const transformPolygonsToGeoJSON = (polygons: number[][][]): GeoJSON.FeatureCollection => {
    const reversedPolygons = [...polygons].reverse(); // crea una copia e poi inverte
    return {
        type: 'FeatureCollection',
        features: reversedPolygons.map((polygon, index) => ({
            id: index,
            type: 'Feature',
            properties: {
                color: reversedPolygons.length === 1 ? 'grey' : getColorByIndex(index, reversedPolygons.length - 1)
            },
            geometry: {
                type: 'Polygon',
                coordinates: [polygon]
            }
        }))
    };
}



const MapDisplay: React.FC<Props> = ({ data, onPolygonSelect }) => {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<mapboxgl.Map | null>(null);
    const negativePoi = transformToGeoJSON(data[3]);
    const positivePoi = transformToGeoJSON(data[4]);
    const descriptivePoi = transformToGeoJSON(data[15]);
    const lastClickedFeatureId = useRef<string | number | undefined>();
    const centerEl = document.createElement('div');
    centerEl.style.backgroundImage = 'url(/flag-blue.png)';
    centerEl.style.width = '25px'; // puoi personalizzare queste dimensioni
    centerEl.style.height = '25px';
    centerEl.style.backgroundSize = 'contain';


    useEffect(() => {
        // Inizializzazione della mappa
        map.current = new mapboxgl.Map({
            container: mapContainer.current as any,
            style: 'mapbox://styles/mapbox/streets-v12',
            preserveDrawingBuffer: true
        });

        // Una volta che la mappa è caricata
        map.current.on('load', function() {
            const layers = map.current!.getStyle().layers;  // Ottieni tutti i layer dello stile attuale
            map.current!.addControl(new mapboxgl.NavigationControl());
            /*const imageContainer = document.createElement('div');
            imageContainer.style.position = 'absolute';
            imageContainer.style.top = '10px';
            imageContainer.style.left = '10px';
            imageContainer.style.width = '120px'; // Imposta la larghezza dell'immagine
            imageContainer.style.height = '50px'; // Imposta l'altezza dell'immagine
            imageContainer.style.backgroundImage = 'url(../logoPythagorMappa.png)';
            imageContainer.style.backgroundSize = 'contain';
            imageContainer.style.backgroundRepeat = 'no-repeat';
            const scale = new mapboxgl.ScaleControl({
                maxWidth: 100,
                unit: 'metric' // Puoi scegliere tra 'imperial' o 'metric'
            });*/
            //map.current?.addControl(scale, 'bottom-right');

            // Aggiungi l'elemento all'interno del container della mappa
            //mapContainer.current?.appendChild(imageContainer);


            // Scorri ogni layer e verifica se fa parte dei POI o delle etichette dei luoghi
            for (let layer of layers) {
                if (layer.id.includes('poi') || layer.id.includes('place') || layer.id.includes('icon')) {
                    map.current!.setLayoutProperty(layer.id, 'visibility', 'none');  // Nascondi il layer
                }
            }
            if (data) {
                const polygons = transformPolygonsToGeoJSON(data[7]);
                const center = data[2];


                map.current!.addLayer({
                    'id': 'maine',
                    'type': 'fill',
                    'source': {
                        'type': 'geojson',
                        'data': polygons
                    },
                    'layout': {},
                    'paint': {
                        'fill-color': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            '#000080', // Blu scuro
                            ['get', 'color']
                        ],
                        'fill-opacity': [
                            'case',
                            ['boolean', ['feature-state', 'click'], false],
                            0.5, // Opacity al 50%
                            0.3
                        ]

                    }
                });



                map.current!.on('click', function(e) {
                    const features = map.current!.queryRenderedFeatures(e.point, { layers: ['maine'] });

                    if (features.length) {
                        const feature = features[0];

                        // Resetta l'ultimo poligono cliccato se esiste
                        if (lastClickedFeatureId.current !== undefined) {
                            map.current!.setFeatureState(
                                { source: 'maine', id: lastClickedFeatureId.current },
                                { click: false }
                            );
                        }

                        // Imposta il nuovo poligono come cliccato
                        map.current!.setFeatureState(
                            { source: 'maine', id: feature.id },
                            { click: true }
                        );

                        lastClickedFeatureId.current = feature.id;
                        onPolygonSelect(feature.id);

                    } else {
                        // Se non è stato cliccato alcun poligono, resetta l'ultimo poligono cliccato
                        if (lastClickedFeatureId.current !== undefined) {
                            map.current!.setFeatureState(
                                { source: 'maine', id: lastClickedFeatureId.current },
                                { click: false }
                            );
                            lastClickedFeatureId.current = undefined;
                            onPolygonSelect(null);
                        }
                    }
                });


                for (const feature of negativePoi.features) {
                    // create a HTML element for each feature
                    const el = document.createElement('div');
                    el.className = 'marker';

                    // make a marker for each feature and add to the map
                    new mapboxgl.Marker(el)
                        .setLngLat([feature.geometry.coordinates[0], feature.geometry.coordinates[1]])
                        .setPopup(
                            new mapboxgl.Popup({ offset: 25 }) // add popups
                                .setHTML(
                                    `<h3>${feature.properties.title}</h3><p>${feature.properties.description}</p>`
                                )
                        )
                        .addTo(map.current!);
                }

                for (const feature of positivePoi.features) {
                    // create a HTML element for each feature
                    const el = document.createElement('div');
                    el.className = 'marker-positive';

                    // make a marker for each feature and add to the map
                    new mapboxgl.Marker(el)
                        .setLngLat([feature.geometry.coordinates[0], feature.geometry.coordinates[1]])
                        .setPopup(
                            new mapboxgl.Popup({ offset: 25 }) // add popups
                                .setHTML(
                                    `<h3>${feature.properties.title}</h3><p>${feature.properties.description}</p>`
                                )
                        )
                        .addTo(map.current!);
                }

                for (const feature of descriptivePoi.features) {
                    const el = document.createElement('div');
                    el.className = 'marker-descriptive';

                    new mapboxgl.Marker(el)
                        .setLngLat([feature.geometry.coordinates[0], feature.geometry.coordinates[1]])
                        .setPopup(
                            new mapboxgl.Popup({ offset: 25 }) // add popups
                                .setHTML(
                                    `<h3>${feature.properties.title}</h3><p>${feature.properties.description}</p>`
                                )
                        )
                        .addTo(map.current!);
                }

                new mapboxgl.Marker(centerEl)
                    .setLngLat([center["longitude"], center["latitude"]])
                    .addTo(map.current!);


                // Centra la mappa sul centro
                map.current!.setCenter([center["longitude"], center["latitude"]]);
                map.current!.setZoom(13);
            }
        });

        return () => {
            map.current?.remove();
        };
    }, [data]);

    return <div ref={mapContainer} style={{ width: '100%', height: '400px' }}></div>;
}

export default MapDisplay;
