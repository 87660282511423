import {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {createTheme, ThemeProvider} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import RedirectCountdown from "../../component/RedirectCountdown";
import { UserService } from "../../services/UserService";

interface SignupPageProps {
    onSignupSuccess: () => void;
}

export default function SignupPage({ onSignupSuccess }: SignupPageProps) {
    const [userInfo, setUserInfo] = useState({ nome: '', cognome: '', email: '', password: '' });
    const [error, setError] = useState<string | null>(null);
    const [redirectCountdown, setRedirectCountdown] = useState<number | null>(null);

    const isFormValid = () => {
        return userInfo.nome && userInfo.cognome && userInfo.email && userInfo.password;
    };

    const handleSignup = () => {
        UserService.signup(userInfo)
            .then(data => {
                if (data.success) {
                    setRedirectCountdown(5);
                } else {
                    setError(data.message);
                }
            })
            .catch(err => {
                setError(err.message);
            });
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setUserInfo((prev) => ({ ...prev, [name]: value }));
    };

    const theme = createTheme();


    return (
        <div className="signup-container">
            {redirectCountdown !== null ? (
                <RedirectCountdown initialCount={redirectCountdown} onComplete={onSignupSuccess} />
            ) : (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="form"
                        noValidate
                        sx={{ mt: 3 }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSignup();
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="nome"
                                    required
                                    fullWidth
                                    id="nome"
                                    label="Nome"
                                    autoFocus
                                    value={userInfo.nome}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="cognome"
                                    label="Cognome"
                                    name="cognome"
                                    autoComplete="family-name"
                                    value={userInfo.cognome}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    value={userInfo.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    value={userInfo.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!isFormValid()}
                            style={{backgroundColor: "#163A5F", color: "white"}}
                        >
                            Registrati
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>

            )}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
}