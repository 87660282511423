import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const loadingPhrases = [
    'Analisi mappa...',
    'Analisi profilo di avvicinamento...',
    'Analisi densità anagrafica nell\'area...',
    'Analisi POI nell\'area...',
    'Elaborazione modello Theorem...'
];

interface LoadingScreenProps {
    isVisible: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ isVisible }) => {
    const [loadingIndex, setLoadingIndex] = useState<number>(0);
    const lastMessageIndex = loadingPhrases.length - 1;  // Cambiato per tenere il messaggio su "Elaborazione modello Theorema..."

    useEffect(() => {
        if (isVisible) {
            const intervalId = setInterval(() => {
                if (loadingIndex === lastMessageIndex) {
                    // Se è su "Elaborazione modello Theorema..." e sta ancora caricando i dati, non incrementare l'indice
                    return;
                } else if (loadingIndex >= loadingPhrases.length - 1) {
                    clearInterval(intervalId);
                } else {
                    setLoadingIndex((prevIndex) => prevIndex + 1);
                }
            }, 2000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [isVisible, loadingIndex]);

    if (!isVisible) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
            <CircularProgress style={{ marginBottom: '16px' }} />
            <p>{loadingPhrases[loadingIndex]}</p>
        </div>
    );
};


export default React.memo(LoadingScreen);
