import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface MultiSelectInputProps {
    options: string[];
    label: string;
    selectedValues?: string[];  // aggiunto questo
    onChange?: (selected: string[]) => void;  // e questo
    disabledInput?: boolean;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> =  ({ options, label, selectedValues, onChange, disabledInput, ...otherProps }) => {
    const theme = useTheme();
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

    const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        if (onChange) {
            onChange(value as string[]); // casting the value
        }
    };



    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="generic-multiple-chip-label">{label}</InputLabel>
                <Select
                    labelId="generic-multiple-chip-label"
                    id="generic-multiple-chip"
                    multiple
                    value={selectedValues || []}
                    onChange={handleSelectionChange}
                    input={<OutlinedInput id="select-multiple-chip" label={label} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    disabled={disabledInput}
                    MenuProps={MenuProps}
                >
                    {options.map((type) => (
                        <MenuItem
                            key={type}
                            value={type}
                            style={{ fontWeight: selectedOptions.indexOf(type) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium }}
                        >
                            {type}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultiSelectInput;
