import React from 'react';
import Paper from '@mui/material/Paper';
import MapDisplay from '../../component/MapDisplay';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import Papa from 'papaparse';
import { describe_selected_poi, negative_selected_poi, positive_selected_poi, question, selected_options } from './DataProcessing';
import * as XLSX from 'xlsx';


interface ReportComponentProps {
    data: any;
    onClose: () => void;
    onPolygonSelect: (index: any) => void;
}

const ReportComponent: React.FC<ReportComponentProps> = ({
    data,
    onClose,
    onPolygonSelect,
}) => {
    const formatNumber = (number: any) => {
        return new Intl.NumberFormat('it-IT').format(number);
    };

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF();
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        // Aggiungi il logo in alto a sinistra
        const logoImg = new Image();
        logoImg.src = '../logoPythagorMappa.png';
        const logoWidth = 40; // Larghezza desiderata in pixel
        const logoHeight = (logoWidth / logoImg.width) * logoImg.height; // Calcolo dell'altezza proporzionale
        const rowData = data[0].map((item: any, index: number) => [
            formatNumber(Math.round(item[0])),
            formatNumber(Math.round(data[12][index][1])), // Utilizzo index per accedere al dato corrispondente in data[12]
            formatNumber(Math.round(item[1])),
            formatNumber(Math.round(item[3])),
        ]);

        const secondTableData = data[0].map((item: any, index: number) => [
            formatNumber(Math.round(item[0])),
            formatNumber(Math.round(item[3])),
            formatNumber(Math.round(data[13][index + 1] || 0)),
            "-" + formatNumber(Math.round(data[14][index + 1] || 0)),
            formatNumber(Math.round(combinedData[index].population)),
        ]);


        pdf.addImage(logoImg, 'PNG', 10, 10, logoWidth, logoHeight);
        const componentContainer = document.getElementById('report-container');
        if (componentContainer) {
            const canvas = await html2canvas(componentContainer, { useCORS: true });
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgData, 'JPEG', 10, 50, 190, 0);
        }

        // Aggiungi una nuova pagina se necessario
        pdf.addPage();

        autoTable(pdf, {
            head: [['Raggio isocrona', 'Popolazione complessiva', 'Popolazione In target', 'Popolazione ridotta in target']],
            body: rowData,
            startY: 20,
        });


        // Aggiungi la seconda tabella al PDF
        const startYSecondTable = (pdf as any).lastAutoTable.finalY + 10;
        autoTable(pdf, {
            head: [['Raggio isocrona', 'Popolazione ridotta in target', 'Popolazione POI positivi', 'Popolazione POI negativi', 'Popolazione potenziale']],
            body: secondTableData,
            startY: startYSecondTable,
        });

        pdf.setFontSize(8);
        const footer = '©Pythagor Srls';
        pdf.text(footer, width / 2, height - 10, { align: 'center' });

        if (componentContainer) {
            try {
                pdf.save('reportPythagor.pdf');
                generateExcel();
            } catch (error) {
                console.error('Errore durante la generazione del PDF:', error);
            }
        }
    };
    const combinedData = data[0].map((item: any) => {
        const clientiPoi = Math.round(data[11][item[0]]) || 0;
        const potenzialiClientiDopoPerdita = Math.round(item[3]);
        const totalPopulation = potenzialiClientiDopoPerdita + clientiPoi;
        return {

            population: totalPopulation
        };
    });

    const generateExcel = () => {
        // Crea un nuovo workbook
        const wb = XLSX.utils.book_new();
    
        // Prepara i dati per le prime due tabelle
        const firstTableData = [
            ['Raggio isocrona', 'Popolazione complessiva', 'Popolazione In target', 'Popolazione ridotta in target'],
            ...data[0].map((item: number[], index: string | number) => [
                formatNumber(Math.round(item[0])),
                formatNumber(Math.round(data[12][index][1])),
                formatNumber(Math.round(item[1])),
                formatNumber(Math.round(item[3])),
            ])
        ];
    
        const secondTableData = [
            ['Raggio isocrona', 'Popolazione ridotta in target', 'Popolazione POI positivi', 'Popolazione POI negativi', 'Popolazione potenziale'],
            ...data[0].map((item: number[], index: number) => [
                formatNumber(Math.round(item[0])),
                formatNumber(Math.round(item[3])),
                formatNumber(Math.round(data[13][index + 1] || 0)),
                "-" + formatNumber(Math.round(data[14][index + 1] || 0)),
                formatNumber(Math.round(combinedData[index].population)),
            ])
        ];
    
        // Unisci le prime due tabelle in un unico foglio
        const combinedFirstTwoTables = [...firstTableData, [''], ...secondTableData];
        const ws1 = XLSX.utils.aoa_to_sheet(combinedFirstTwoTables);
        XLSX.utils.book_append_sheet(wb, ws1, "Riepilogo e Dettagli");
    
        // Prepara i dati per i POI in un secondo foglio
        const poiData = [
            ['POI Descrittivi', 'Tipo', 'Isocrona'],
            ...data[15].map((poi: { name: any; type: any; isocrona: any; }) => [poi.name, poi.type, poi.isocrona]),
            [''],
            ['POI Positivi', 'Tipo', 'Isocrona'],
            ...data[4].map((poi: { name: any; type: any; isocrona: any; }) => [poi.name, poi.type, poi.isocrona]),
            [''],
            ['POI Negativi', 'Tipo', 'Isocrona'],
            ...data[3].map((poi: { name: any; type: any; isocrona: any; }) => [poi.name, poi.type, poi.isocrona])
        ];
        const ws2 = XLSX.utils.aoa_to_sheet(poiData);
        XLSX.utils.book_append_sheet(wb, ws2, "POI");

        const popolazioneEstesaData = [
            ['Raggio isocrona', 'Popolazione residente', 'Stranieri e apolidi', 'Italiani', 'Maschi', 'Femmine', 'Celibi/nubili', 'Coniugati/e (+ separati/e di fatto)', 'Separati/e legalmente', 'Vedovi/e', 'Divorziati/e', 'Età < 5 anni', 'Età 5 - 9 anni', 'Età 10 - 14 anni', 'Età 15 - 19 anni', 'Età 20 - 24 anni', 'Età 25 - 29 anni', 'Età 30 - 34 anni', 'Età 35 - 39 anni', 'Età 40 - 44 anni', 'Età 45 - 49 anni', 'Età 50 - 54 anni', 'Età 55 - 59 anni', 'Età 60 - 64 anni', 'Età 65 - 69 anni', 'Età 70 - 74 anni', 'Età > 74 anni', 'Popolazione con titoli universitari o non post-diploma', 'Popolazione con diploma scuola secondaria superiore', 'Popolazione con media inferiore', 'Popolazione con licenza elementare', 'Popolazione senza titolo di studio', 'Popolazione appartenente alle forze di lavoro', 'Popolazione non appartenente alle forze di lavoro'],
        ];
        
        data[17].forEach((entry: any) => {
            const rowData = [
                entry[0],
                formatNumber(Math.round(entry[1]["Popolazione residente"])),
                formatNumber(Math.round(entry[1]["Stranieri e apolidi"])),
                formatNumber(Math.round(entry[1]["Italiani"])),
                formatNumber(Math.round(entry[1]["Maschi"])),
                formatNumber(Math.round(entry[1]["Femmine"])),
                formatNumber(Math.round(entry[1]["Celibi/nubili"])),
                formatNumber(Math.round(entry[1]["Coniugati/e (+ separati/e di fatto)"])),
                formatNumber(Math.round(entry[1]["Separati/e legalmente"])),
                formatNumber(Math.round(entry[1]["Vedovi/e"])),
                formatNumber(Math.round(entry[1]["Divorziati/e"])),
                formatNumber(Math.round(entry[1]["Et  < 5 anni"])),
                formatNumber(Math.round(entry[1]["Et  5 - 9 anni"])),
                formatNumber(Math.round(entry[1]["Et  10 - 14 anni"])),
                formatNumber(Math.round(entry[1]["Et  15 - 19 anni"])),
                formatNumber(Math.round(entry[1]["Et  20 - 24 anni"])),
                formatNumber(Math.round(entry[1]["Et  25 - 29 anni"])),
                formatNumber(Math.round(entry[1]["Et  30 - 34 anni"])),
                formatNumber(Math.round(entry[1]["Et  35 - 39 anni"])),
                formatNumber(Math.round(entry[1]["Et  40 - 44 anni"])),
                formatNumber(Math.round(entry[1]["Et  45 - 49 anni"])),
                formatNumber(Math.round(entry[1]["Et  50 - 54 anni"])),
                formatNumber(Math.round(entry[1]["Et  55 - 59 anni"])),
                formatNumber(Math.round(entry[1]["Et  60 - 64 anni"])),
                formatNumber(Math.round(entry[1]["Et  65 - 69 anni"])),
                formatNumber(Math.round(entry[1]["Et  70 - 74 anni"])),
                formatNumber(Math.round(entry[1]["Et  > 74 anni"])),
                formatNumber(Math.round(entry[1]["pop res 6 anni e pi  con titoli universitari o non post dipl"])),
                formatNumber(Math.round(entry[1]["pop res 6 anni e pi  con diploma scuola secondaria superiore"])),
                formatNumber(Math.round(entry[1]["pop res 6 anni e pi  con media inferiore"])),
                formatNumber(Math.round(entry[1]["pop res 6 anni e pi  con licenza elementare"])),
                formatNumber(Math.round(entry[1]["pop res 6 anni e pi  senza titolo di studio"])),
                formatNumber(Math.round(entry[1]["pop res 15 anni e pi  appartenente alle forze di lavoro"])),
                formatNumber(Math.round(entry[1]["pop res 15 anni e pi  non appartenente alle forze di lavoro"])),
            ];
            popolazioneEstesaData.push(rowData);
        });
        
        // Aggiungi il nuovo foglio al workbook
        const ws3 = XLSX.utils.aoa_to_sheet(popolazioneEstesaData);
        XLSX.utils.book_append_sheet(wb, ws3, "Popolazione estesa dati");
        
        // Genera il file XLSX e offre il download come prima
        XLSX.writeFile(wb, 'reportPythagor.xlsx');        
    };
    
    


    const StyledButton = styled(Button)({
        backgroundColor: '#163A5F', // Blue shade for primary buttons
        color: 'white',
        '&:hover': {
            backgroundColor: '#1A6AA6', // Slightly darker shade on hover
        }
    });
    let density = 0;
    let customers = 0;
    let poiConcorrenziali = data[3].length;
    let poiPositivi = data[4].length;
    if (data && data[0]) {
        data[0].forEach(function (singolaZona: any) {
            density += singolaZona[1];
            customers += singolaZona[3];
        });
    }
    density = parseInt(density.toFixed(0));
    customers = data[0].reduce((acc: number, item: number[]) => {
        const clientiPoi = Math.round(data[11][item[0].toString()]) || 0;
        const potenzialiClientiDopoPerdita = Math.round(item[3]);
        return acc + (potenzialiClientiDopoPerdita + clientiPoi);
    }, 0);
    if (customers < 0)
        customers = 0;
    const dataCorrente = new Date();
    const poiData = JSON.parse(sessionStorage.getItem('poiData') || '{}');
    const findPoiLabels = (selectedValues: string[], poiList: any[]) => {
        const labelsSet = new Set(
            selectedValues.map(value =>
                poiList.find(poi => poi.codifica_tipologia === value)?.nome_tipologia
            ).filter(label => label)
        );
        return Array.from(labelsSet).join(', ');
    };
    const positivePoiLabels = findPoiLabels(positive_selected_poi, poiData.positivo || []);
    const negativePoiLabels = findPoiLabels(negative_selected_poi, poiData.negativo || []);
    const descriptivePoiLabels = findPoiLabels(describe_selected_poi, poiData.descrittivo || []);



    return (
        <div>
            <div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <img src="../logoPythagorMappa.png" style={{ maxWidth: "120px", width: "100%", height: "100%" }}></img>
                    <p style={{ fontSize: '11px', textAlignLast: 'center' }}><i>Seleziona la porzione di mappa che preferisci e genera il report PDF con l'apposito pulsante...</i></p>
                </div>
                <div id="report-container">
                    <h2>Report</h2>
                    <p>L'interrogazione al software Theorem, in data {dataCorrente.toLocaleDateString()}, è stata eseguita sulla base dei seguenti parametri:</p>
                    <ul>
                        <li><strong>indirizzo: </strong>{question}</li>
                        <li><strong>avvicinamento: </strong>{sessionStorage.getItem('routing_profile_selected')}</li>
                        <li><strong>distanza: </strong>{data[8].distance} minuti</li>
                        <li><strong>obiettivo anagrafico: </strong>{selected_options.join(', ')}</li>
                        <li><strong>POI positivi: </strong>{positivePoiLabels}</li>
                        <li><strong>POI concorrenziali: </strong>{negativePoiLabels}</li>
                        <li><strong>POI descrittivi: </strong>{descriptivePoiLabels}</li>
                    </ul>
                    <p>Risultati: </p>
                    <ul>
                        <li>Densità demografica: {formatNumber(density)} persone;</li>
                        <li>Presenti {poiPositivi} POI positivi;</li>
                        <li>Presenti {poiConcorrenziali} POI negativi</li>
                        <li>Presenti {data[15].length} POI descrittivi</li>
                        <li>Potenziali clienti: {formatNumber(customers)} persone.</li>
                    </ul>
                    <div id="map-image">
                        <MapDisplay data={data} onPolygonSelect={onPolygonSelect} />
                    </div>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <StyledButton variant="contained" onClick={onClose} sx={{ mt: 3, ml: 1 }}>
                        Chiudi Report
                    </StyledButton>
                    <StyledButton variant="contained" onClick={handleGeneratePdf} sx={{ mt: 3, ml: 1 }}>
                        Genera PDF e CSV
                    </StyledButton>

                </Box>
            </div>
        </div>
    );
};

export default ReportComponent;
