import React, { useState, useEffect } from 'react';
import { Box, Chip, Typography, Divider, Button } from '@mui/material';

interface MultiSelectWithMacroProps {
  poiData: any; // Usa la nuova struttura dati qui
  label: string;
  selectedValues?: string[];
  onChange?: (selected: string[]) => void;
  disabledSelect : boolean;
}

const MultiSelectWithMacro: React.FC<MultiSelectWithMacroProps> = ({
  poiData,
  label,
  selectedValues,
  onChange,
  disabledSelect,
}) => {

  const [selectedMacroArea, setSelectedMacroArea] = useState<string | null>(null);

  // Estrai i nomi delle categorie uniche
  const uniqueCategories = Array.from(new Set(poiData.map((item: { nome_categoria: any; }) => item.nome_categoria)));

  const handleMacroAreaClick = (area: string) => {
    setSelectedMacroArea(area);
  };


  const handleMicroAreaChange = (codificaTipologia: string, isSelected: boolean) => {
    let newSelectedValues = selectedValues ? [...selectedValues] : [];

    if (isSelected) {
      // Rimuovi la codifica se già selezionata
      newSelectedValues = newSelectedValues.filter((item) => item !== codificaTipologia);
    } else {
      // Aggiungi la codifica se non già presente
      newSelectedValues.push(codificaTipologia);
    }

    onChange?.(newSelectedValues);
  };

  // Raggruppa i dati per nome_categoria
  const groupedData = poiData.reduce((acc: any, item: any) => {
    (acc[item.nome_categoria] = acc[item.nome_categoria] || []).push(item);
    return acc;
  }, {});

  const codificaToNomeMap = poiData.reduce((acc: any, item: any) => {
    acc[item.codifica_tipologia] = item.nome_tipologia;
    return acc;
  }, {});

  const nomeToCodificheMap = poiData.reduce((acc: any, item: any) => {
    if (!acc[item.nome_tipologia]) {
      acc[item.nome_tipologia] = [];
    }
    acc[item.nome_tipologia].push(item.codifica_tipologia);
    return acc;
  }, {});

  const handleNomeTipologiaChange = (nomeTipologia: string, isSelected: boolean) => {
    let updatedSelectedValues = selectedValues ? [...selectedValues] : [];

    if (isSelected) {
      // Rimuovi tutte le codifica_tipologia relative a nomeTipologia
      nomeToCodificheMap[nomeTipologia].forEach((codifica: string) => {
        updatedSelectedValues = updatedSelectedValues.filter((val) => val !== codifica);
      });
    } else {
      // Aggiungi tutte le codifica_tipologia relative a nomeTipologia
      updatedSelectedValues.push(...nomeToCodificheMap[nomeTipologia]);
    }

    onChange?.(updatedSelectedValues);
  };

  const selectedNomi = new Set<string>();
  selectedValues?.forEach((codifica) => {
    const nome = codificaToNomeMap[codifica];
    if (nome) {
      selectedNomi.add(nome);
    }
  });

  const filteredDataByCategory = poiData.filter((item: any) => item.nome_categoria === selectedMacroArea);

  // Aggiorna nomeToCodificheMap in base ai dati filtrati
  const nomeToCodificheFilteredMap = filteredDataByCategory.reduce((acc: any, item: any) => {
    if (!acc[item.nome_tipologia]) {
      acc[item.nome_tipologia] = [];
    }
    acc[item.nome_tipologia].push(item.codifica_tipologia);
    return acc;
  }, {});

  const selectAllOptions = () => {
    if (selectedMacroArea) {
      // Estrai tutte le codifiche dalla categoria selezionata
      const allCodificheInCategory = Object.entries(nomeToCodificheFilteredMap).flatMap(
        ([nomeTipologia, codifiche]) => codifiche as string[]
      );

      // Unisci le nuove codifiche con quelle già selezionate, rimuovendo i duplicati
      const updatedSelectedValues = Array.from(new Set([...(selectedValues || []), ...allCodificheInCategory]));

      onChange?.(updatedSelectedValues);
    }
  };


  return (
    <Box sx={{ my: 2 }}>
      {/* Render selected chips globally */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
        {Array.from(selectedNomi).map((nome) => (
          <Chip
            size='small'
            key={nome}
            label={nome}
            onDelete={() => handleNomeTipologiaChange(nome, true)}
            color="primary"
            sx={{ fontSize: '0.75rem', margin: '5px' }}
          />
        ))}
      </Box>
      {/* Horizontal header */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: '4px',
      }}>
        {uniqueCategories.map((area, index) => (
          <React.Fragment key={area as string}>
            {index > 0 && <Divider orientation="vertical" flexItem />}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                py: 0.5,
                cursor: 'pointer',
                padding: '10px',
                backgroundColor: selectedMacroArea === area ? 'action.selected' : 'background.paper',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
              onClick={() => handleMacroAreaClick(area as string)}
            >
              <Typography variant="body2" sx={{ fontWeight: 500, fontSize: '0.75rem' }}>
                {area as string}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
      {/* Micro areas for the selected macro area */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
        {selectedMacroArea &&
          Object.entries(nomeToCodificheFilteredMap).map(([nomeTipologia, codifiche]) => (
            <Chip
              key={nomeTipologia}
              label={nomeTipologia}
              disabled={disabledSelect}
              onClick={() => handleNomeTipologiaChange(
                nomeTipologia,
                (codifiche as string[]).some(codifica => selectedValues?.includes(codifica))
              )}
              color={(codifiche as string[]).some(codifica => selectedValues?.includes(codifica)) ? 'primary' : 'default'}
              sx={{ fontSize: '0.75rem', margin: '5px' }}
            />
          ))
        }
      </Box>
      {/* pulsante "Seleziona Tutto" */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, paddingTop:"15px" }}>
        {/*<Typography variant="h8">{label}</Typography>*/}   
        {selectedMacroArea && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={disabledSelect}
            sx={{backgroundColor: "#163a5f"}}
            onClick={selectAllOptions}
          >
            Seleziona Tutto
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MultiSelectWithMacro;
