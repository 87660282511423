import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Paper, Typography, Stack, Avatar } from '@mui/material';
import ResponsiveAppBar from '../../component/CustomNavbar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { UserService } from '../../services/UserService';
import ResetPasswordButton from '../../component/ResetPasswordButton';

const SettingsPage: React.FC = () => {
    const [userInfo, setUserInfo] = useState({
        nome: '',
        email: '',
        tipologia: ''
    });
    const navigate = useNavigate();

    useEffect(() => {

        setUserInfo({
            nome: sessionStorage.getItem("nomeAccount") + " " + sessionStorage.getItem("cognomeAccount"),
            email: sessionStorage.getItem("emailAccount") + "",
            tipologia: sessionStorage.getItem("tipologiaAccount") + ""
        });

    }, []);

    return (
        <>
            <ResponsiveAppBar />
            <Container maxWidth="md" sx={{ mt: 8 }}>
                <Paper elevation={6} sx={{ p: 4, mb: 3, borderRadius: 3 }}>
                    <Box display="flex" justifyContent="center" mb={3}>
                        <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>
                            <LockOutlinedIcon />
                        </Avatar>
                    </Box>
                    <Typography variant="h5" gutterBottom align="center" mb={3}>
                        Informazioni Account
                    </Typography>

                    <Stack direction="column" divider={<hr style={{ border: "none", borderTop: "1px solid #eee" }} />} spacing={2}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body1">
                                <strong>Nome:</strong> {userInfo.nome}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body1">
                                <strong>Tipologia account:</strong> {userInfo.tipologia}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="body1">
                                <strong>Email:</strong> {userInfo.email}
                            </Typography>
                        </Box>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="body1">
                                <strong>Password:</strong> **********
                            </Typography>
                            <ResetPasswordButton />
                        </Stack>
                    </Stack>
                </Paper>

                <Box display="flex" justifyContent="center">
                    <Button variant="contained" style={{ backgroundColor: "#163A5F", color: "white" }} onClick={() => { navigate('/processing') }}>
                        Torna alla home
                    </Button>
                </Box>
            </Container>
        </>
    );
};

export default SettingsPage;
