import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProcessingRoute from "./ModelloStradaPages/ProcessingRoute";
import SignupPage from "./AccessPages/SignupPage";
import LoginPage from "./AccessPages/LoginPage";

const HomePage = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
    const navigate = useNavigate();

    const handleSwitchToSignup = () => {
        setShowSignup(true);
    };

    const handleLoginSuccess = () => {
        setIsAuthenticated(true);
        navigate("/processing"); // Navigate to the processing route upon login
    };

    const handleSignupSuccess = () => {
        if (isAuthenticated) {
            setShowSignup(false);
            navigate("/processing");
        }; // Navigate to the processing route upon signup
    };

    // Render the appropriate component based on the current state
    let content = (
        <LoginPage
            onLoginSuccess={handleLoginSuccess}
            onSwitchToSignup={handleSwitchToSignup}
        />
    );

    if (isAuthenticated) {
        content = <ProcessingRoute />;
    } else if (showSignup) {
        content = (
            <SignupPage
                onSignupSuccess={handleSignupSuccess}
            />
        );
    }

    return <div>{content}</div>;
};

export default HomePage;
