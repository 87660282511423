type FixedData = {
    question: string;
    routing_profile: string;
    countour_type: string;
    distance: string;
    selected_options: string[];
    positive_poi: string[];
    negative_poi: string[];
};


export class StradaService {
    static async fetchPlaces(fixedData: FixedData) {
        const token = sessionStorage.getItem('jwtToken'); // Get the JWT token from sessionStorage

        const headers: Record<string, string> = {
            'Content-Type': 'application/json'
        };

        // Include the Authorization header if the token exists
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        const response = await fetch("https://backend.pythagor.it/places/", {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(fixedData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    }


}
