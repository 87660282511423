import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';




const pages = [''];
const settings = ['Impostazioni', 'Logout'];

function ResponsiveAppBar() {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [avatarColor, setAvatarColor] = React.useState('#ffffff');

    React.useEffect(() => {
        let color = sessionStorage.getItem('avatarColor');
        if (!color) {
            color = `#${Math.floor(Math.random() * 0xFFFFFF).toString(16)}`;
            sessionStorage.setItem('avatarColor', color);
        }
        setAvatarColor(color);
    }, []);

    const nome = sessionStorage.getItem('nomeAccount');
    const cognome = sessionStorage.getItem('cognomeAccount');
    const nomeCompleto = nome + " " + cognome;
    const iniziali = `${nome ? nome.charAt(0) : ''}${cognome ? cognome.charAt(0) : ''}`;
    const tipologiaAccount = sessionStorage.getItem('tipologiaAccount');
    const showSignup = tipologiaAccount === 'Demo' || tipologiaAccount === 'Guest';

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        // Aggiorna semplicemente la pagina
        window.location.reload();
    };

    const handleSettingsOptionClick = (option: string) => {
        if (option === "Impostazioni") {
            navigate('/settings');
        }
        if (option === "Logout") {
            sessionStorage.removeItem('jwtToken');
            navigate('/');

        }
        handleCloseUserMenu();
    };

    const handleGoToPremium = () => {
        window.open('https://www.pythagor.it', '_blank');
    };


    return (
        <AppBar position="static" sx={{ backgroundColor: "#163a5f" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <a href="https://www.pythagor.it/" target="_blank"><img src="/logoPythagor.png" style={{ maxWidth: "190px" }} /></a>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "right", paddingRight: "15px" }}>
                        {showSignup && (
                            <Button
                                onClick={handleGoToPremium}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                Passa a Premium
                            </Button>
                        )}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Account" sx={{ bgcolor: avatarColor }}>{iniziali}</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem disabled sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)', mb: 1 }}>
                                <Typography
                                    textAlign="center"
                                    sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}
                                >
                                    {nomeCompleto}
                                </Typography>
                            </MenuItem>
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={() => handleSettingsOptionClick(setting)}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;