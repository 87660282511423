import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button, styled } from '@mui/material';
import Box from "@mui/material/Box";
import MultiSelectInput from "../../component/MultiSelectInput";
import MultiSelectExpandable from '../../component/MultiSelectExpandable';
import MultiSelectWithMacro from '../../component/MultiSelectExpandable';

interface DemographicSelectionFormData {
    demographicTarget: string[];
}

interface MultiSelectInputProps {
    options: string[];
    label: string;
    selectedValues?: string[];
    onChange?: (selected: string[]) => void;
}

interface DemographicSelectionFormProps {
    currentData: { demographicTarget: string[] };
    onUpdate: (data: string[] | DemographicSelectionFormData) => void;
    handleBack: () => void;
    handleNext: () => void;
}


const StyledButton = styled(Button)({
    backgroundColor: '#163A5F',
    color: 'white',
    '&:hover': {
        backgroundColor: '#1A6AA6',
    }
});

const anagrafico = [
    { value: 'all', label: 'Tutta la popolazione' },
    { value: 'Maschi', label: 'Uomini' },
    { value: 'Femmine', label: 'Donne' },
    { value: 'Stranieri e apolidi', label: 'Stranieri e apolidi' },
    { value: 'Italiani', label: 'Italiani' },
    { value: 'Et  < 5 anni', label: 'Età < 5 anni' },
    { value: 'Et  5 - 9 anni', label: 'Età 5 - 9 anni' },
    { value: 'Et  10 - 14 anni', label: 'Età 10 - 14 anni' },
    { value: 'Et  15 - 19 anni', label: 'Età 15 - 19 anni' },
    { value: 'Et  20 - 24 anni', label: 'Et  20 - 24 anni' },
    { value: 'Et  25 - 29 anni', label: 'Età 25 - 29 anni' },
    { value: 'Et  30 - 34 anni', label: 'Età 30 - 34 anni' },
    { value: 'Et  35 - 39 anni', label: 'Età 35 - 39 anni' },
    { value: 'Et  40 - 44 anni', label: 'Età 40 - 44 anni' },
    { value: 'Et  45 - 49 anni', label: 'Età 45 - 49 anni' },
    { value: 'Et  50 - 54 anni', label: 'Età 50 - 54 anni' },
    { value: 'Et  55 - 59 anni', label: 'Età 55 - 59 anni' },
    { value: 'Et  60 - 64 anni', label: 'Età 60 - 64 anni' },
    { value: 'Et  65 - 69 anni', label: 'Età 65 - 69 anni' },
    { value: 'Et  70 - 74 anni', label: 'Età 70 - 74 anni' },
    { value: 'Et  > 74 anni', label: 'Età > 74 anni' },
    { value: 'pop res 6 anni e pi  senza titolo di studio', label: 'Senza titolo di studio' },
    { value: 'pop res 6 anni e pi  con licenza elementare', label: 'Licenza Elementare' },
    { value: 'pop res 6 anni e pi  con media inferiore', label: 'Licenza Media' },
    { value: 'pop res 6 anni e pi  con diploma scuola secondaria superiore', label: 'Diploma' },
    { value: 'pop res 6 anni e pi  con titoli universitari o non post dipl', label: 'Laurea' },
    { value: 'pop res 15 anni e pi  appartenente alle forze di lavoro', label: 'Lavoratori' },
    { value: 'pop res 15 anni e pi  non appartenente alle forze di lavoro', label: 'Disoccupati' }
];

const DemographicSelectionForm: React.FC<DemographicSelectionFormProps> = (props) => {
    const [demographicTarget, setDemographicTarget] = React.useState<string[]>(props.currentData.demographicTarget);
    const [isGuest, setIsGuest] = React.useState(false);

    React.useEffect(() => {
        if (sessionStorage.getItem('tipologiaAccount') === 'Guest') {
            setIsGuest(true);
            // Imposta qui i valori predefiniti per gli ospiti, se necessario
        } else {
            setIsGuest(false);
        }
    }, []);


    const handleUpdate = (data: string[]) => {
        // Aggiorna direttamente la proprietà nel componente di stato
        setDemographicTarget(data);

        // Invia i dati aggiornati al componente padre
        props.onUpdate(data);
    };



    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                <b>Obiettivo anagrafico</b>
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                Seleziona una o più fasce anagrafiche sulle quali verrà applicata la ricerca.
            </Typography>
            <Grid item xs={12} md={6}>
                <MultiSelectInput
                    options={anagrafico.map(opt => opt.label)}
                    label="Obiettivo anagrafico"
                    selectedValues={demographicTarget.map(value =>
                        anagrafico.find(opt => opt.value === value)?.label || '')}
                    onChange={(selectedLabels) => {
                        const selectedValues = selectedLabels
                            .map(label => anagrafico.find(opt => opt.label === label)?.value)
                            .filter((value): value is string => value !== undefined);
                        handleUpdate(selectedValues);
                    }}
                    disabledInput={isGuest}
                />
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => props.handleBack()} sx={{ mt: 3, ml: 1 }}>
                    Back
                </Button>
                <StyledButton
                    variant="contained"
                    onClick={() => props.handleNext()}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Continua
                </StyledButton>
            </Box>
        </React.Fragment>
    );
}

export default DemographicSelectionForm;